import { useReducer, createContext, useMemo, ReactNode } from 'react'

type Type = 'openMenu' | 'openCart' | 'close' | 'toggleMenu' | 'toggleCart'

type Action = {
  type: Type
}

type Arg = 'menu' | 'cart'

type Context = {
  isShowingMenu: boolean
  isShowingCart: boolean
  openModal: (_x: Arg) => void
  closeModal: (_x: Arg) => void
}

const ModalContext = createContext(null)

function reducer(state: Context, action: Action) {
  switch (action.type) {
    case 'openMenu':
      return { ...state, isShowingCart: false, isShowingMenu: true }

    case 'openCart':
      return { ...state, isShowingCart: true, isShowingMenu: false }

    case 'toggleCart':
      return {
        ...state,
        isShowingCart: !state.isShowingCart,
        isShowingMenu: false
      }

    case 'close':
      return { ...state, isShowingCart: false, isShowingMenu: false }

    case 'toggleMenu':
      return {
        ...state,
        isShowingCart: false,
        isShowingMenu: !state.isShowingMenu
      }

    default:
      state
  }
}

const initialState = {
  isShowingMenu: false,
  isShowingCart: false
}

export function ModalContextProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState as Context)

  const openModal = (type: 'openMenu' | 'openCart') => dispatch({ type })

  const value = useMemo(
    () => ({
      openMenu: () => openModal('openMenu'),
      openCart: () => openModal('openCart'),
      closeModal: () => dispatch({ type: 'close' }),
      toggleMenu: () => dispatch({ type: 'toggleMenu' }),
      toggleCart: () => dispatch({ type: 'toggleCart' })
    }),
    []
  )

  return (
    <ModalContext.Provider value={{ ...state, ...value }}>
      {children}
    </ModalContext.Provider>
  )
}

export default ModalContext
