type Props = {
  children?: any
  className?: string
  notchPosition: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
  aspectW?: number
  aspectH?: number
}

export default function NotchedCard({
  children,
  className,
  notchPosition,
  aspectW,
  aspectH
}: Props) {
  let path = 'polygon(0 0, 95% 0, 100% 8%, 100% 100%, 0 100%'
  if (aspectW && aspectH) {
    let scaleW = aspectW <= aspectH
    let scaleH = !scaleW
    let scale = scaleW ? aspectW / aspectH : aspectH / aspectW
    path = `polygon(0 0, ${
      scaleW ? (100 - 8 / scale).toString() : '92'
    }% 0, 100% ${scaleH ? (8 / scale).toString() : '8'}%, 100% 100%, 0 100%`
    if (notchPosition === 'top-left') {
      path = `polygon(0 ${scaleH ? (8 / scale).toString() : '8'}%, ${
        scaleW ? (8 / scale).toString() : '8'
      }% 0, 100% 0, 100% 100%, 0 100%`
    } else if (notchPosition === 'bottom-left') {
      path = `polygon(0 0, 100% 0, 100% 100%, ${
        scaleW ? (8 / scale).toString() : '8'
      }% 100%, 0 ${scaleH ? (100 - 8 / scale).toString() : '92'}%`
    } else if (notchPosition === 'bottom-right') {
      path = `polygon(0 0, 100% 0, 100% ${
        scaleH ? (100 - 8 / scale).toString() : '92'
      }%, ${scaleW ? (100 - 8 / scale).toString() : '92'}% 100%, 0 100%`
    }
  } else {
    if (notchPosition === 'top-left') {
      path = 'polygon(0 8%, 5% 0, 100% 0, 100% 100%, 0 100%'
    } else if (notchPosition === 'bottom-left') {
      path = 'polygon(0 0, 100% 0, 100% 100%, 5% 100%, 0 92%'
    } else if (notchPosition === 'bottom-right') {
      path = 'polygon(0 0, 100% 0, 100% 92%, 95% 100%, 0 100%'
    }
  }

  return (
    <div
      className={className ? className : ''}
      style={{
        clipPath: path
      }}
    >
      {children}
    </div>
  )
}
