type Props = {
  className?: string
  isOpen?: boolean
}

export default function IconPlus({ className = '', isOpen }: Props) {
  const transform = `transition-transform transform ${
    isOpen && 'rotate-45'
  } fill-current w-4 h-4 md:w-6 md:h-6`

  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={transform.concat(className)}
    >
      <path
        d="M0.75 12H23.25"
        stroke="#030303"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 0.75V23.25"
        stroke="#030303"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
