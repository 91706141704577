import { useState } from 'react'
import Image from 'next/image'

import Plus from '~/components/icons/IconPlus'
import Minus from '~/components/icons/IconMinus'
import Trash from '~/svg/trash.svg'
import ToggleIcon from '~/components/MenuToggle'
import { formatPrice } from '~/utils/functions'

type Props = {
  title: string
  quantity: number
  variant: any
  onRemove: () => void
  onIncrease: () => void
  onDecrease: () => void
}

export default function CartItem({
  title,
  quantity,
  variant,
  onRemove,
  onIncrease,
  onDecrease
}: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const handleToggle = () => setIsOpen((x) => !x)

  const { altText, src } = variant.product?.images?.edges[0]?.node || {}

  return (
    <>
      <div className="md:flex gap-x-5 justify-between lg:gap-x-8">
        <div className="flex flex-row gap-x-4 mt-6 items-center md:flex-grow">
          {src && (
            <div className="w-28 md:w-36">
              <Image
                src={src}
                width={110}
                height={82}
                objectFit="cover"
                layout="responsive"
                className="rounded-lg"
                alt={altText}
              />
            </div>
          )}
          <div className="flex-grow">
            <p className="text-grey-300">{title}</p>
            <p className="mt-2 text-grey-300 text-xs">
              {formatPrice(variant.priceV2.amount)}
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-between mt-6 items-center md:gap-x-8 lg:gap-x-16">
          <div className="flex items-center gap-x-5 border border-grey-200 p-3 rounded-lg">
            <button onClick={onDecrease}>
              <Minus />
            </button>
            <span className="text-xs">{quantity}</span>
            <button onClick={onIncrease}>
              <Plus className="w-4 h-4" />
            </button>
          </div>
          <button
            onClick={onRemove}
            className="p-2 bg-grey-200 rounded-full"
            type="button"
          >
            <Trash />
          </button>
        </div>
      </div>
      <div
        className={`${
          isOpen ? 'pt-4' : 'py-4'
        } border-t border-grey-700 mt-6 md-8`}
      >
        <div className="flex justify-between">
          <p className="font-medium">Order Details</p>
          <ToggleIcon type={isOpen ? 'close' : 'open'} onClick={handleToggle} />
        </div>
        <div hidden={!isOpen}>
          <div className="flex flex-col gap-y-2.5 py-8 md:flex-row">
            <p className="text-grey-300 text-sm leading-none md:w-3/5">MODEL</p>
            <p className="text-sm leading-none">Model Z</p>
          </div>
          <div className="flex flex-col gap-y-2.5 border-t border-grey-700 py-8 md:flex-row">
            <p className="text-grey-300 text-sm leading-none md:w-3/5">
              COLOUR
            </p>
            <p className="text-sm leading-none">Space Grey</p>
          </div>
          <div className="flex flex-col gap-y-2.5 border-t border-grey-700 py-8 md:flex-row">
            <p className="text-grey-300 text-sm leading-none md:w-3/5">
              DELIVERY
            </p>
            <p className="text-sm leading-none">Free Delivery</p>
          </div>
          <div className="flex flex-col gap-y-2.5 border-t border-grey-700 py-8 md:flex-row">
            <p className="text-grey-300 text-sm leading-none md:w-3/5">
              IN THE BOX
            </p>
            <ul className="flex flex-col gap-y-2.5 md:w-2/5">
              <li>
                <p className="text-sm leading-none">1x Mary Unit</p>
              </li>
              <li>
                <p className="text-sm leading-none">1x Power Cord</p>
              </li>
              <li>
                <p className="text-sm leading-none">
                  1x Supply Kit (Odor Filter, Pre-Measured Nutrients, Drying
                  String)
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
