import * as yup from 'yup'

let addressSchema = yup.object().shape({
  firstName: yup.string().required('*Required'),
  lastName: yup.string().required('*Required'),
  email: yup
    .string()
    .email('*Email must be a valid email')
    .required('*Required'),
  phone: yup.string(),
  line1: yup.string().notOneOf(['']).required('*Required'),
  line2: yup.string(),
  city: yup.string().notOneOf(['']).required('*Required'),
  province: yup.string().notOneOf(['']).required('*Required'),
  postCode: yup.string().notOneOf(['']).required('*Required'),
  country: yup.string().oneOf(['Canada', 'United States']).required('*Required')
})

export default addressSchema
