import { useContext, useEffect, useState } from 'react'
import Link from 'next/link'
import ReactPlaceholder from 'react-placeholder'
import 'react-placeholder/lib/reactPlaceholder.css'
import { motion, AnimatePresence } from 'framer-motion'
import { Dialog } from '@headlessui/react'

import { formatPrice } from '~/utils/functions'
import { ShopifyContext } from '~/context/ShopifyContext'
import ModalContext from '~/context/ModalContext'
import HeaderContext from '~/context/HeaderContext'
import CartItem from '~/components/CartItem'
import BoxDashed from '~/svg/box-dashed.svg'
import IconX from '~/components/icons/IconX'
import { getPaymentSchedule } from '~/lib/bnpl'
import BNPLDialog from './BNPLDialog'

type Schedule = {
  amount: number,
  date: string,
  dueFromNow: string,
  paymentNumber: string,
  totalAmount: string
}

type PaymentSchedule = {
  meta: {
    numPayments: number,
    totalAmount: string
  },
  schedule: Schedule[]
}

export default function Cart() {
  const { checkout, remove, increase, decrease } = useContext(ShopifyContext)
  const { isShowingCart, closeModal } = useContext(ModalContext)
  const { setBgBlack } = useContext(HeaderContext)
  const [shouldShowBNPLOption, setShouldShowBNPLOption] = useState(false)
  const [isShowingBNPLDialog, setIsShowingBNPLDialog] = useState(false)
  const [paymentSchedule, setPaymentSchedule] = useState({} as PaymentSchedule)
  
  useEffect(() => {
    getPaymentSchedule(setPaymentSchedule)
  }, [isShowingBNPLDialog])

  useEffect(() => {
    if (!isShowingCart && isShowingBNPLDialog) setIsShowingBNPLDialog(false)
  }, [isShowingCart])

  useEffect(() => {
    const { title, quantity } = checkout?.lineItems?.edges?.[0]?.node || {}
    if (title?.includes('Mary') && quantity === 1) {
      setShouldShowBNPLOption(true)
    } else if (shouldShowBNPLOption) {
      setShouldShowBNPLOption(false)
    }
  }, [checkout])

  return (
    <AnimatePresence initial={false}>
      {isShowingCart && (
        <Dialog
          open={isShowingCart}
          onClose={() => null}
          className="z-50"
          static
        >
          (
          <motion.section
            initial={{ opacity: 0.15 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 top-20 md:top-21 z-50 bg-white text-grey-700 md:flex"
          >
            <div className="hidden bg-grey-700 md:block md:w-1/4 lg:w-2/5 z-40" />
            {isShowingBNPLDialog ? (
              <BNPLDialog
                key="bnpl-dialog"
                isVisible={isShowingBNPLDialog}
                closeDialog={() => { setIsShowingBNPLDialog(false) }}
                paymentSchedule={paymentSchedule}
              />
            ) : (
              <div className="md:flex flex-col w-full h-screen md:w-3/4 lg:w-3/5">
                <div className="pl-6 pr-12 -mr-6 pb-32 flex-grow border-t h-screen border-grey-200 lg:px-16 pt-16 overflow-y-scroll overscroll-contain">
                  <div>
                    <span className="font-medium text-black md:text-lg flex flex-row justify-between items-center">
                      <span>Your Cart</span>
                      <button
                        onClick={() => {
                          closeModal()
                          setBgBlack()
                        }}
                        className="hidden md:block"
                      >
                        <IconX className="w-9 h-9" />
                      </button>
                    </span>
                    {checkout.lineItems?.edges &&
                    checkout.lineItems.edges.length ? (
                      <>
                        <ReactPlaceholder
                          ready={true}
                          type="media"
                          showLoadingAnimation
                        >
                          <div className="mt-6">
                            <CartItem
                              {...checkout.lineItems.edges[0].node}
                              onRemove={remove(
                                checkout.lineItems.edges[0].node.id
                              )}
                              onIncrease={increase(
                                checkout.lineItems.edges[0].node.variant.id,
                                checkout.lineItems.edges[0].node.id
                              )}
                              onDecrease={decrease(
                                checkout.lineItems.edges[0].node.variant.id,
                                checkout.lineItems.edges[0].node.id
                              )}
                            />
                          </div>
                        </ReactPlaceholder>
                        <div className="text-sm border-t border-b border-grey-700 mt-6 py-6 leading-none md:mt-8 md:py-8 md:text-base">
                          <div className="flex justify-between">
                            <span>Taxes</span>
                            <span className="text-grey-300">
                              Tax will be calculated based on shipping address.
                            </span>
                          </div>
                          <div className="flex justify-between mt-4 md:mt-5">
                            <span>Shipping</span>
                            <span className="text-grey-300">Free</span>
                          </div>
                          <div className="flex justify-between mt-4 font-medium text-base leading-none md:mt-5 md:text-lg">
                            <span>Total</span>
                            <span>
                              {formatPrice(checkout.totalPriceV2.amount)}
                            </span>
                          </div>
                        </div>
                        <div className="flex space-between justify-end mt-8 md:mt-12">
                          <div className="flex mx-8">
                            {shouldShowBNPLOption && (
                              <button
                                onClick={_ => setIsShowingBNPLDialog(true)}
                                type="button"
                                className="bg-grey-700 py-5 px-8 rounded-full text-white text-sm leading-none md:text-base md:py-6 md:px-20"
                              >
                                Pay in installments
                              </button>
                            )}
                          </div>
                          <div className="flex">
                            <a
                              className="bg-grey-700 py-5 px-8 rounded-full text-white text-sm leading-none md:text-base md:py-6 md:px-20"
                              href={checkout.webUrl}
                              rel="noopener noreferrer"
                            >
                              Checkout
                            </a>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-col items-center mt-8">
                        <BoxDashed />
                        <p className="font-medium mt-10">
                          Your Shopping Cart is Empty
                        </p>
                        <p className="text-sm text-grey-300 mt-4">
                          Looks Like You Haven&apos;t Added Anything To Your Cart
                        </p>
                        <Link href="/products">
                          <a className="bg-grey-700 text-sm py-5 px-8 text-white rounded-full mt-8">
                            <span onClick={closeModal}>Explore Grow Box</span>
                          </a>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </motion.section>
          )
        </Dialog>
      )}
    </AnimatePresence>
  )
}
