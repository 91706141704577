import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Header from './Header'
import Footer from './Footer'
import { ModalContextProvider } from '~/context/ModalContext'
import Menu from './Menu'
import HeaderContext from '~/context/HeaderContext'
import Cart from '~/components/Cart'

export default function Container({ className, children }) {
  const [headerState, setHeaderState] = useState(false)
  const headerContext = {
    isBgWhite: headerState,
    setBgWhite: () => {
      setHeaderState(true)
    },
    setBgBlack: () => {
      setHeaderState(false)
    },
    toggleBgColour: () => {
      setHeaderState(!headerState)
    }
  }

  return (
    <HeaderContext.Provider value={headerContext}>
      <ModalContextProvider>
        <main className="flex flex-col min-h-screen">
          <Header />
          <Menu />
          <Cart />
          <section className={className ? className : ''}>
            <a id="start-of-content"></a>
            {children}
          </section>
          <Footer />
        </main>
      </ModalContextProvider>
    </HeaderContext.Provider>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired
}
