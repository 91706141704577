import React, { useContext } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { motion, AnimatePresence } from 'framer-motion'
import { Dialog } from '@headlessui/react'

import MenuContext from '~/context/ModalContext'
import HeaderContext from '~/context/HeaderContext'
import CurrencyDropdown from '~/components/CurrencyDropdown'
import NotchedCard from '~/components/NotchedCard'

export default function Menu() {
  const { isShowingMenu, closeModal } = useContext(MenuContext)
  const { toggleBgColour } = useContext(HeaderContext)

  const handleClose = () => {
    toggleBgColour()
    closeModal()
  }

  return (
    <AnimatePresence initial={false}>
      {isShowingMenu && (
        <Dialog
          open={isShowingMenu}
          onClose={() => null}
          className="z-50 "
          static
        >
          <div
            className="fixed inset-0 bg-black opacity-30 z-40 top-24"
            onClick={handleClose}
          >
            <Dialog.Overlay />
          </div>
          <motion.menu
            initial={{ opacity: 0.15 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="bg-white w-screen h-screen fixed overflow-hidden overscroll-contain top-20 md:top-21 z-40 m-0 p-0 lg:h-auto border-t border-grey-700 border-opacity-10"
          >
            <div className="md:hidden z-40 h-full overflow-y-scroll -mr-6 pr-6">
              <div className="px-6 pb-6 text-grey-700 ">
                <ul>
                  <li className="mt-6 pb-6">
                    <div className="flex flex-col">
                      <span className="text-grey-700 text-opacity-50 pb-2">
                        Grow Box
                      </span>
                      <NotchedCard
                        className="relative text-center cursor-pointer"
                        notchPosition="top-right"
                        aspectW={272}
                        aspectH={204}
                      >
                        <Link href="/products/model-z">
                          <a className="relative text-center">
                            <Image
                              src="/images/grow-box.png"
                              width={272}
                              height={204}
                              layout="responsive"
                              objectFit="cover"
                              className="rounded-lg"
                              alt="Model Z"
                            />
                          </a>
                        </Link>
                        <Link href="/products/model-z">
                          <div className="absolute bottom-4 left-4 flex flex-col items-start">
                            <span className="text-white">Mary Model Z</span>
                            <span className="text-white text-sm">$1,899</span>
                          </div>
                        </Link>
                      </NotchedCard>
                    </div>
                  </li>
                  {/* <li className="py-4 border-b border-grey-200">
              <Link href="/products">
                <a className="flex justify-between items-center">
                  <span>More Products</span>
                  <Arrow direction="right" />
                </a>
              </Link>
            </li> */}
                  {/* <li className="border-b border-grey-200">
                  <Link href="/growers">
                    <a className="flex justify-between items-center py-4">
                      <span>Growers</span>
                      <Arrow direction="right" />
                    </a>
                  </Link>
                </li> */}
                  {/* <li className="border-b border-grey-200">
                  <Link href="/blog">
                    <a className="flex justify-between items-center py-4">
                      <span>Blog</span>
                      <Arrow direction="right" />
                    </a>
                  </Link>
                </li> */}
                  {/* <li className="border-b border-grey-200">
                  <Link href="/support">
                    <a className="flex justify-between items-center py-4">
                      <span>Support</span>
                      <Arrow direction="right" />
                    </a>
                  </Link>
                </li> */}
                </ul>
                <div className="flex justify-end">
                  {/* <CurrencyDropdown /> */}
                </div>
              </div>
            </div>
            <div
              className="hidden md:flex max-w-7xl mx-auto gap-x-16 py-14 p-6"
              onClick={handleClose}
            >
              <NotchedCard
                className="text-center flex-shrink-0 w-1/2 lg:w-auto"
                notchPosition="top-right"
                aspectW={488}
                aspectH={366}
              >
                <Image
                  src="/images/grow-box.png"
                  alt="Model Z Growbox"
                  width={488}
                  height={366}
                  objectFit="cover"
                  className="rounded-lg"
                />
              </NotchedCard>
              <div className="text-grey-700 flex flex-col self-end">
                <h3 className="text-xl lg:text-3xl leading-1">Mary Model Z</h3>
                <p className="mt-8 mb-12 text-sm leading-10">
                  A fully automated single plant enclosure that will teach you
                  how to care for your plant and get the most out of every
                  harvest. This climate controlled smart grow box will help you
                  turn any living space into an ideal growing environment for
                  the plant of your choice.
                </p>
                <div className="mb-4">
                  <span onClick={handleClose}>
                    <Link href="/products/model-z">
                      <a className="text-sm py-4 px-5 bg-pink-500 rounded-full">
                        Explore Grow Box
                      </a>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </motion.menu>
        </Dialog>
      )}
    </AnimatePresence>
  )
}
