type Props = {
  darkTheme?: boolean
  rotate?: boolean
}

export default function IconDropDownArrow({ darkTheme, rotate }: Props) {
  const stroke = darkTheme ? 'white' : 'black'
  const rotateClass = rotate ? 'transform rotate-180' : ''

  return (
    <div className={rotateClass}>
      <svg
        width="10"
        height="8"
        viewBox="0 0 10 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.75 2.43701L5.17667 6.01001C5.15348 6.03323 5.12594 6.05166 5.09563 6.06422C5.06531 6.07679 5.03282 6.08326 5 6.08326C4.96718 6.08326 4.93469 6.07679 4.90437 6.06422C4.87406 6.05166 4.84652 6.03323 4.82333 6.01001L1.25 2.43701"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}
