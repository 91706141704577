const lambdaBNPLURL = 'https://rtsxztkdq6.execute-api.us-east-2.amazonaws.com/mary-bnpl'

export const getPaymentSchedule =
  (f: any) =>
    fetch(
      `${lambdaBNPLURL}/payment-schedule`,
      { method: 'GET' }
    )
      .then((x) => x.json())
      .then(f)
      .catch(console.log)
      
export const initPaymentOrder =
  ({ addressForm }) =>
  (f: any) =>
    fetch(
      `${lambdaBNPLURL}/init-order`,
      {
        method: 'POST',
        body: JSON.stringify(addressForm)
      }
    )
      .then((x) => x.json())
      .then(f)
      .catch(console.log)
