import React from 'react'

const HeaderContext = React.createContext({
  isBgWhite: false,
  setBgWhite: () => {},
  setBgBlack: () => {},
  toggleBgColour: () => {}
})

export const HeaderProvider = HeaderContext.Provider
export const HeaderConsumer = HeaderContext.Consumer

export default HeaderContext
