import * as React from 'react'
import { useContext } from 'react'
import Link from 'next/link'

import MaryAGWhiteLogo from '~/svg/maryag-white.svg'
import MaryAGBlackLogo from '~/svg/maryag-black.svg'
import IconDropDownArrow from '~/components/icons/IconDropDownArrow'
import MenuToggle from '~/components/MenuToggle'
import MenuContext from '~/context/ModalContext'
import HeaderContext from '~/context/HeaderContext'
import CartButton from '~/components/CartButton'
import CurrencyDropdown from '~/components/CurrencyDropdown'

export default function Header() {
  const { isShowingMenu, isShowingCart, toggleMenu, toggleCart } =
    useContext(MenuContext)
  const { isBgWhite, setBgBlack, setBgWhite } = useContext(HeaderContext)

  return (
    <header
      className={`top-0 z-40 overscroll-y-none border-b border-opacity-10 sticky px-6 ${
        isBgWhite
          ? 'bg-white text-black border-grey-700 w-screen md:pr-10'
          : 'bg-black border-white text-white w-full'
      }

      `}
    >
      <div className="w-full flex justify-between transition duration-300 items-center max-w-7xl md:mx-auto py-7">
        <Link href="/">
          <a title="MaryAG" className="h-6 w-28">
            {isBgWhite ? <MaryAGBlackLogo /> : <MaryAGWhiteLogo />}
          </a>
        </Link>
        <div className={'hidden md:flex md:mx-auto'}>
          <div
            className="inline-flex items-center pr-8 md:pr-0 cursor-pointer"
            onClick={() => {
              isShowingMenu ? setBgBlack() : setBgWhite()
              toggleMenu()
            }}
          >
            <span
              className={`text-sm ${
                isBgWhite ? 'text-black font-medium' : 'text-white'
              } pr-2`}
            >
              Products
            </span>
            <IconDropDownArrow darkTheme={!isBgWhite} rotate={isBgWhite} />
          </div>

          <Link href="/growers">
            <a
              className={`text-sm px-8 ${
                isBgWhite ? 'text-black' : 'text-white'
              }`}
            >
              Growers
            </a>
          </Link>
          <Link href="https://technology.mary.ag">
            <a
              className={`text-sm ${
                isBgWhite ? 'text-black' : 'text-white'
              }`}
            >
              Technology
            </a>
          </Link>
          {/*
          <Link href="/blog">
            <a className={`pr-8 ${isBgWhite ? 'text-black' : 'text-white'}`}>
              Blog
            </a>
          </Link>
          <Link href="/support">
            <a className={`${isBgWhite ? 'text-black' : 'text-white'}`}>
              Support
            </a>
          </Link>
          */}
        </div>
        <div className="flex flex-row ml-5 items-center">
          <div className={'hidden sm:flex flex-row items-center pr-8'}>
            {/* <CurrencyDropdown darkTheme={!isBgWhite} /> */}
          </div>
          {/* <CartButton
            className="ml-auto focus:outline-none"
            onClick={() => {
              isShowingCart ? setBgBlack() : setBgWhite()
              toggleCart()
            }}
            darkTheme={!isBgWhite}
          /> */}
          <MenuToggle
            darkTheme={!isBgWhite}
            className="sm:hidden ml-5"
            onClick={() => {
              isShowingMenu ? setBgBlack() : setBgWhite()
              toggleMenu()
            }}
            type={isShowingMenu ? 'close' : 'open'}
          />
        </div>
      </div>
    </header>
  )
}
