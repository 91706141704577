import { useContext, useState } from 'react'
import 'react-placeholder/lib/reactPlaceholder.css'
import { Formik, Form, useField } from 'formik'

import { listOfRegions } from '~/utils/constants'
import { ShopifyContext } from '~/context/ShopifyContext'
import IconArrow from '~/components/icons/IconArrow'
import addressSchema from '~/schema/address-schema'
import { initPaymentOrder } from '~/lib/bnpl'

const TextInput = ({
  label,
  filledAndValid,
  lg,
  ...props
}: {
  [x: string]: any
  name: string
  filledAndValid: boolean
  lg?: boolean
}) => {
  const [field, meta, helpers] = useField(props)

  return (
    <div className="lg:pb-0 flex flex-col">
      <div className="">
        <div className="inline-flex">
          {meta.touched && meta.error ? (
            <div className="error text-red-500 text-sm p-0 pl-4">
              {meta.error}
            </div>
          ) : null}
        </div>

        <div className="w-full bg-grey-200 rounded-md h-auto">
          <input
            autoComplete="off"
            className="h-12 ml-1 pr-10 text-input border-none focus:ring-0 text-sm placeholder-grey-300 w-full rounded-md bg-transparent"
            {...field}
            {...props}
          />
        </div>
      </div>
    </div>
  )
}
const SelectInput = ({
  label,
  filledAndValid,
  ...props
}: {
  [x: string]: any
  name: string
  filledAndValid: boolean
}) => {
  const [field, meta] = useField(props)
  return (
    <div className="h-full">
      <div className="inline-flex">
        {meta.touched && meta.error ? (
          <div className="error text-red-500 text-sm p-0 pl-4">
            {meta.error}
          </div>
        ) : null}
      </div>
      <div className=" w-full">
        <div className="flex flex-row justify-between items-center p-0 w-full">
          <style jsx>{`
            select:invalid {
              color: #696969;
              hidden
            }
          `}</style>
          <select
            autoComplete="off"
            id="dropdown"
            required
            {...field}
            {...props}
            className="bg-grey-200 rounded-md border-none focus:ring-0 text-sm w-full pl-4 text-black"
          />
        </div>
      </div>
    </div>
  )
}

export default function BNPLDialog({ isVisible, closeDialog, paymentSchedule }) {
  const { currency } = useContext(ShopifyContext)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [line1, setLine1] = useState('')
  const [line2, setLine2] = useState('')
  const [city, setCity] = useState('')
  const [province, setProvince] = useState('')
  const [postCode, setPostCode] = useState('')
  const [gratifyRedirectURL, setGratifyRedirectURL] = useState('')
  const [country, setCountry] = useState(currency === 'CAD' ? 'Canada' : 'United States')
  const [isLoading, setIsLoading] = useState(false)

  const saveForm = (values) => {
    setFirstName(values.firstName)
    setLastName(values.lastName)
    setEmail(values.email)
    setPhone(values.phone)
    setLine1(values.line1)
    setLine2(values.line2)
    setCity(values.city)
    setProvince(values.province)
    setPostCode(values.postCode)
    setCountry(values.country)
  }

  const ContactForm = () => (
    <Formik
      initialValues={{
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        line1: line1,
        line2: line2,
        city: city,
        province: province,
        postCode: postCode,
        country: country,
      }}
      validationSchema={addressSchema}
      onSubmit={(values, actions) => {
        saveForm(values)
        const addressForm = {
          firstName,
          lastName,
          email,
          phone,
          line1,
          line2,
          city,
          province: listOfRegions[country][province],
          postCode,
          country
        }
        if (province && email) {
          setIsLoading(true)
          initPaymentOrder({ addressForm })(({ redirectURL }) => {
            setGratifyRedirectURL(redirectURL)
            window.location.href = redirectURL
          })
        }
        actions.setSubmitting(false)
      }}
    >
      {({ isValid, dirty }) => (
        <Form
          className="flex flex-col pb-28"
        >
          <div className="mb-4 lg:grid grid-rows-4 grid-cols-2 grid-flow-row gap-x-4">
            <div className="row-span-1 col-span-2 flex flex-col justify-end lg:pt-6">
              <TextInput
                required
                label="email"
                name="email"
                type="text"
                placeholder="Email"
                title="Email"
                filledAndValid={isValid && dirty}
              />
            </div>
            <div className="row-span-1 flex flex-col justify-end">
              <TextInput
                required
                label="firstName"
                name="firstName"
                type="text"
                placeholder="First Name"
                title="First Name"
                filledAndValid={isValid && dirty}
              />
            </div>
            <div className="row-span-1 flex flex-col justify-end lg:pt-6">
              <TextInput
                required
                label="lastName"
                name="lastName"
                type="text"
                placeholder="Last Name"
                title="Last Name"
                filledAndValid={isValid && dirty}
              />
            </div>
            <div className="row-span-1 flex flex-col justify-end lg:pt-6">
              <TextInput
                required
                label="line1"
                name="line1"
                type="text"
                placeholder="Address"
                title="Address Line 1"
                filledAndValid={isValid && dirty}
              />
            </div>
            <div className="row-span-1 flex flex-col justify-end lg:pt-6">
              <TextInput
                label="line2"
                name="line2"
                type="text"
                placeholder="Apartment, suite, etc. (optional)"
                title="Address Line 2"
                filledAndValid={isValid && dirty}
              />
            </div>
            <div className="row-span-1 flex flex-col justify-end lg:pt-6">
              <TextInput
                required
                label="city"
                name="city"
                type="text"
                placeholder="City"
                title="City"
                filledAndValid={isValid && dirty}
              />
            </div>
            <div className="row-span-1 flex flex-col justify-end lg:pt-6 text-white">
              <SelectInput
                label="province"
                name="province"
                title="Province"
                filledAndValid={isValid && dirty}
              >
                <option value="" selected disabled hidden>
                  {country === 'Canada' ? 'Province' : 'State'}
                </option>
                {Object.keys(listOfRegions[country]).map((option) => {
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  )
                })}
              </SelectInput>
            </div>
            <div className="row-span-1 flex flex-col justify-end lg:pt-6">
              <TextInput
                required
                label="postCode"
                name="postCode"
                type="text"
                placeholder={country === 'Canada' ? 'Postal Code' : 'Zip'}
                title={country === 'Canada' ? 'Postal Code' : 'Zip'}
                filledAndValid={isValid && dirty}
              />
            </div>
            <div className="row-span-1 flex flex-col justify-end lg:pt-6">
              <TextInput
                label="phone"
                name="phone"
                type="text"
                placeholder="Phone (optional)"
                title="Phone Number"
                filledAndValid={isValid && dirty}
              />
            </div>
            <div className="row-span-1 flex flex-col justify-end lg:pt-6 text-white">
              <SelectInput
                onChange={v => { setCountry(v.nativeEvent.target.value) }}
                label="country"
                name="country"
                title="Country"
                filledAndValid={isValid && dirty}
              >
                <option value="" selected disabled hidden>
                  Country
                </option>
                {Object.keys(listOfRegions).map((option) => {
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  )
                })}
              </SelectInput>
            </div>
          </div>
          <button
            disabled={isLoading}
            type="submit"
            className="bg-grey-700 py-5 px-8 rounded-full text-white text-sm leading-none md:text-base md:py-6 md:px-20 justify-end self-end"
          >
            {isLoading ? 'Loading..' : 'Checkout'}
          </button>
          {gratifyRedirectURL &&
            <a href={gratifyRedirectURL} className="flex my-6 justify-end">
              Click here if you are not automatically redirected
            </a>
          }
        </Form>
      )}
    </Formik>
  )

  return (
    <div className="pl-6 pr-12 -mr-6 pb-32 flex-grow border-t h-screen border-grey-200 lg:px-16 pt-16 overflow-y-scroll overscroll-contain">
      <div>
        <span className="font-medium text-black md:text-lg flex flex-row justify-between items-center">
          <button
            onClick={closeDialog}
            className="md:block"
          >
            <IconArrow direction="left" strokeWidth="2" />
          </button>
          <span>Payment Schedule</span>
        </span>
        {paymentSchedule.schedule?.map(s =>
          <div key={s.paymentNumber} className="flex items-end justify-end mt-6">
            <span className="mr-2">{s.paymentNumber}:</span>
            <span className="text-lg">{s.totalAmount}</span>
            <span className="ml-1">{s.dueFromNow}</span>
          </div>
        )}
        <span style={{ 'fontStyle': 'italic' }} className="text-xs py-2 justify-end flex">*Payments before any applicable taxes</span>
        <span style={{ 'fontStyle': 'italic' }} className="text-xs py-2 justify-end flex">*Prices in CAD; US orders $1699USD charged in CAD</span>
        <h1 className="text-lg">Shipping Address</h1>
        <ContactForm />
      </div>
    </div>
  )
}
