type Props = {
  onClick?: () => void
  darkTheme?: boolean
  className?: string
  type: 'open' | 'close'
}

export default function ToggleMenuIcon({
  className,
  onClick,
  darkTheme,
  type
}: Props) {
  const stroke = darkTheme ? 'white' : 'black'

  return (
    <button
      type="button"
      onClick={onClick}
      className={`"border-none focus:outline-none bg-none" ${className}`}
    >
      <svg
        className="w-6 h-6"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.25 15.0029H21.75"
          className="transition-transform"
          transform={type === 'close' ? 'rotate(45 15 12)' : ''}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.25 9.00293H21.75"
          className="transition-transform"
          transform={type === 'close' ? 'rotate(-45 15 12)' : ''}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  )
}
