type Direction = 'right' | 'down' | 'left'

type Props = {
  direction: Direction
  colour?: string
  strokeWidth?: string
}

const getRotateDeg = (direction: Direction) =>
  ({
    right: '',
    down: 'rotate-90',
    left: 'rotate-180'
  }[direction])

export default function Arrow({ direction, colour, strokeWidth }: Props) {
  return (
    <div className={`transform ${getRotateDeg(direction)}`}>
      <svg
        width="13"
        height="15"
        viewBox="0 0 13 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.9602 1.40625L9.76633 7.21292C9.80407 7.2506 9.834 7.29535 9.85443 7.34461C9.87485 7.39387 9.88536 7.44667 9.88536 7.5C9.88536 7.55333 9.87485 7.60613 9.85443 7.65539C9.834 7.70465 9.80407 7.7494 9.76633 7.78708L3.96021 13.5938"
          stroke={colour ? colour : '#030303'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth ? strokeWidth : '1'}
        />
      </svg>
    </div>
  )
}
