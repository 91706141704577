import Link from 'next/link'
import Instagram from '~/svg/instagram.svg'
import YouTube from '~/svg/you-tube.svg'
import Twitter from '~/svg/twitter.svg'
import Facebook from '~/svg/facebook.svg'
import GooglePlay from '~/svg/google-play-black.svg'
import AppleStore from '~/svg/apple-store-black.svg'
import Amex from '~/svg/amex.svg'
import ApplePay from '~/svg/apple-pay.svg'
import Flame from '~/svg/flame.svg'
import Discover from '~/svg/discover.svg'
import MasterCard from '~/svg/master-card.svg'
import Visa from '~/svg/visa.svg'
import ShopifyPay from '~/svg/shopify-pay.svg'
import MaryLogo from '~/svg/mary-logo.svg'
import CurrencyDropdown from '~/components/CurrencyDropdown'

const links = [
  {
    text: 'Company',
    links: [
      { label: 'Home', href: '/' },
      /* { label: 'Grow Box', href: '/grow-box' },
       * { label: 'About Us', href: '/about' },
       * { label: 'Careers', href: '/careers' }, */
      { label: 'Investors', href: '/investors' }
    ]
  },
  {
    text: 'Community',
    links: [
      { label: 'Support', href: '/support' },
      { label: 'Growers', href: '/growers' }
      /* { label: 'Blogs', href: '/blog' } */
    ]
  },
  {
    text: 'Need Help?',
    links: [
      { label: 'FAQ', href: '/faq' },
      { label: 'Returns & Refunds', href: '/pages/return-refund-policy' },
      { label: 'Delivery Policy', href: '/pages/delivery-policy' },
      { label: 'Contact Us', href: '/contact-us' }
    ]
  }
]

function FooterLink({ href, label }) {
  return (
    <Link href={href}>
      <a className="text-grey-700 opacity-50">{label}</a>
    </Link>
  )
}

function SocialIcons() {
  return (
    <div className="flex">
      {[
        { icon: Instagram, url: 'https://www.instagram.com/maryaghq/' },
        {
          icon: YouTube,
          url: 'https://www.youtube.com/channel/UClNaaZd4bKk_suFSvgxTSag'
        },
        { icon: Facebook, url: 'https://www.facebook.com/MaryAGHQ/' },
        { icon: Twitter, url: 'https://twitter.com/marydotag' }
      ].map(({ icon: Icon, url }, i) => (
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className="p-4 rounded-full bg-grey-100 mr-2"
          key={i}
        >
          <Icon />
        </a>
      ))}
    </div>
  )
}

function CreditCardIcons() {
  return (
    <div className="flex justify-center gap-4 flex-wrap">
      {[Amex, ApplePay, Flame, Discover, MasterCard, Visa, ShopifyPay].map(
        (Icon, i) => (
          <Icon key={i} />
        )
      )}
    </div>
  )
}

export default function Footer() {
  return (
    <footer className="px-6 py-16 bg-white xl:pt-24">
      <div className="flex flex-row flex-wrap justify-between items-start md:flex-nowrap md:items-start relative max-w-7xl mx-auto">
        <MaryLogo className="w-9 h-6 flex-shrink-0 mb-16" />
        <div className="text-black mb-16 md:order-2 -mt-2">
          {/* <CurrencyDropdown /> */}
        </div>
        <div className="w-full flex flex-row flex-wrap gap-y-8 md:gap-x-16 lg:gap-x-32 md:flex-nowrap md:ml-16 lg:ml-32">
          {links.map(({ text, links }) => (
            <div className="w-1/2 md:w-max md:mr-8" key={text}>
              <span className="text-grey-700">{text}</span>
              <ul className="mt-8">
                {links.map((link) => (
                  <li key={link.label} className="mb-5">
                    <FooterLink {...link} />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col mt-9 md:flex-row md:justify-between max-w-7xl mx-auto">
        <div className="mb-12 lg:mb-0">
          <p className="text-grey-700 mb-5">Follow Mary and stay updated!</p>
          <SocialIcons />
        </div>
        <div>
          <p className="text-grey-700 mb-5 md:text-right">
            Download the app today!
          </p>
          <div className="flex justify-start gap-x-2.5 sm:gap-x-4">
            <Link href="https://play.google.com/store/apps/details?id=com.mary">
              <a title="Google Play Store | Mary AG" target="_blank">
                <GooglePlay
                  width={162}
                  height={48}
                  className="w-[131px] h-[38px] sm:w-[162px] sm:h-[48px]"
                />
              </a>
            </Link>
            <Link href="https://apps.apple.com/app/mary/id1434968352">
              <a title="Apple Store | Mary AG" target="_blank">
                <AppleStore
                  width={162}
                  height={48}
                  className="w-[131px] h-[38px] sm:w-[162px] sm:h-[48px]"
                />
              </a>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col xl:flex-row xl:justify-between xl:items-start border-t border-grey-200 pt-6 sm:pt-16 mt-12 sm:mt-16 mb-10 max-w-7xl mx-auto">
        <p className="text-grey-700 text-sm text-center mb-10 lg:text-left xl:mb-0 lg:max-w-2xl">
          Copyright © 2021 All Rights Reserved by Mary.ag |&nbsp;
          <Link href="/pages/privacy-policy">
            <a className="text-grey-700 opacity-50">Privacy Policy</a>
          </Link>
          <span className="mx-1">|</span>
          <Link href="/pages/terms-and-conditions">
            <a className="text-grey-700 opacity-50">Terms and Conditions</a>
          </Link>
        </p>
        <CreditCardIcons />
      </div>
    </footer>
  )
}
